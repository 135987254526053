import { defineMessages } from 'react-intl';

export default defineMessages({
  TOTAL_PRODUCT_COUNT: {
    id: 'abbs.card.products.count',
    defaultMessage: '<div class="count">{count, number}</div> <div class="label">{count, plural, one {product} other {products}}</div>',
  },
  ABBS_COUNT: {
    id: 'abbs.card.abbs.count',
    defaultMessage: 'In {count, number} {count, plural, one {ABB} other {ABBs}}',
  },
  SEE_ABB_PRODUCTS: {
    id: 'abbs.card.seeProducts',
    defaultMessage: 'See all products of these ABBs',
  },
});
