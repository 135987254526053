import { withStateHandlers } from 'recompose';

export default withStateHandlers(
  () => ({
    isPanelExpanded: false,
  }),
  {
    onTogglePanel: ({ isPanelExpanded }) => () => ({
      isPanelExpanded: !isPanelExpanded,
    }),
  },
);
