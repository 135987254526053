import React, { memo } from 'react';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import ApplicationLayout from 'react/business/layout/ApplicationLayout';
import TitleHeader from 'react/business/layout/titleHeader/TitleHeader';

import ArchitectureBuildingBlocksPage from 'react/pages/architectureBuildingBlocks/ArchitectureBuildingBlocksPage';

import messages from './abb-list.messages';
import classNames from './abb-list.module.scss';

const enhancer = compose(
  memo,
);

const ArchitectureBuildingBlocksList = () => (
  <ApplicationLayout
    mainClassName={classNames.main}
  >
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>
    <TitleHeader>
      <FormattedMessage {...messages.TITLE} />
    </TitleHeader>
    <div className={classNames.content}>
      <ArchitectureBuildingBlocksPage />
    </div>
  </ApplicationLayout>
);

export default enhancer(ArchitectureBuildingBlocksList);
