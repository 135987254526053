import React, { memo } from 'react';
import { compose } from 'recompose';

import ArchitectureBuildingBlocksCardList from 'react/business/architectureBuildingBlocks/card/list/ArchitectureBuildingBlocksCardList';

const enhancer = compose(
  memo,
);

const ArchitectureBuildingBlocksPage = () => (
  <div>
    <ArchitectureBuildingBlocksCardList />
  </div>
);

export default enhancer(ArchitectureBuildingBlocksPage);
